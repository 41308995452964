import { Backdrop, Box, CircularProgress, Typography } from "@mui/material";
import { useSelector } from "react-redux";

const LoadingBackdrop = () => {
  const  {isLoading} = useSelector((state) => state.global);

  return (
    <Backdrop open={isLoading} sx={{ color: "#fff", zIndex: 9999 }}>
      <Box
        sx={{
          backgroundColor: "white",
          padding: 3,
          borderRadius: 2,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          boxShadow: 3,
        }}
      >
        <CircularProgress size={60} color={'error'} />
        <Typography variant="body1" mt={2} color="black">
          Tunggu Sebentar...
        </Typography>
      </Box>
    </Backdrop>
  );
};

export default LoadingBackdrop;
