import React, { useEffect, useState } from "react";
import { Box, Button, IconButton, useTheme } from "@mui/material";
import Header from "components/Header";
import { useDispatch, useSelector } from "react-redux";
import {
  CloudSyncOutlined,
  Delete,
  Groups2Outlined,
} from "@mui/icons-material";
import MUIDataTable from "mui-datatables";
import FlexBetween from "components/FlexBetween";
import {
  fetchAddAgendaSO,
  fetchDeleteAgendaSO,
  fetchImportAgendaSO,
  fetchListSO,
} from "state/stockopnameactions";
import moment from "moment";
import "moment/locale/id";
import AddStockopnameModal from "components/Modals/AddStockopnameModal";
import { fetchListCabang } from "state/karyawanActions";
import FloatingAlert from "components/FloatingAlert";
import { setMessage, setStatus } from "state";
import KonfirmasiModal from "components/Modals/KonfirmasiModal";
moment.locale("id");

const Stockopname = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { list_stockopname } = useSelector((state) => state.so);
  const { status, message } = useSelector((state) => state.global);
  const [openAdd, setOpenAdd] = useState(false);
  const [type, setType] = useState("delete");
  const [selectedData, setSelectedData] = useState(null);
  const [openKonfirmasi, setOpenKonfirmasi] = useState(false);

  useEffect(() => {
    dispatch(fetchListSO());
    dispatch(fetchListCabang());
  }, []);

  const handleCloseSnackbar = () => {
    dispatch(setStatus("default"));
    dispatch(setMessage(""));
    dispatch(fetchListSO());
  };

  const HandleSubmitSO = (reqData) => {
    dispatch(fetchAddAgendaSO(reqData));
  };

  const handleSelectedData = (id) => {
    let currentSelectedData = list_stockopname.find(
      (item) => item.no_register === id
    );
    setSelectedData(currentSelectedData);
  };

  const handleDelete = () => {
    dispatch(fetchDeleteAgendaSO(selectedData.no_register));
    setOpenKonfirmasi(false);
    setSelectedData(null);
    setType("delete");
  };

  const handleImport = () => {
    dispatch(
      fetchImportAgendaSO(
        selectedData.no_register,
        selectedData.id_cab,
        selectedData.lokasi
      )
    );
    setOpenKonfirmasi(false);
    setSelectedData(null);
    setType("delete");
  };

  const columnsStockOpname = [
    {
      name: "no_register",
      label: "No Register",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "lokasi",
      label: "Lokasi",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "id_cab",
      label: "ID Cabang",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "tanggal",
      label: "Tanggal",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          return moment(value).format("DD MMMM YYYY");
        },
      },
    },
    {
      name: "waktu",
      label: "Waktu",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          const today = moment().format("YYYY-MM-DD");
          const dateTime = moment(`${today} ${value}`);
          return dateTime.format("HH:mm");
        },
      },
    },
    {
      name: "isImported",
      label: "Sudah Di Import?",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => (value ? "Sudah" : "Belum"),
      },
    },
    {
      name: "aksi",
      label: "Aksi",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          const id = tableMeta.rowData[0];
          return (
            <>
              {!tableMeta.rowData[5] && (
                <IconButton
                  color="info"
                  onClick={() => {
                    setType("import");
                    setOpenKonfirmasi(true);
                    handleSelectedData(id);
                  }}
                >
                  <CloudSyncOutlined />
                </IconButton>
              )}
              <IconButton
                color="error"
                onClick={() => {
                  setType("delete");
                  setOpenKonfirmasi(true);
                  handleSelectedData(id);
                }}
              >
                <Delete />
              </IconButton>
            </>
          );
        },
      },
    },
  ];

  const options = {
    selectableRows: "none",
    filterType: "dropdown",
    fixedHeader: false,
    fixedSelectColumn: false,
  };

  return (
    <Box m="1.5rem 2.5rem">
      <FlexBetween>
        <Header title="Stockopname" subtitle="Daftar agenda stockopname." />
        <Box>
          <Button
            sx={{
              backgroundColor: theme.palette.secondary.light,
              color: theme.palette.background.alt,
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
              "&:hover": {
                backgroundColor: theme.palette.secondary.light, // Tetap sama dengan warna awal
              },
            }}
            onClick={() => setOpenAdd(true)}
          >
            <Groups2Outlined sx={{ mr: "10px" }} />
            Tambah Stockopname
          </Button>
        </Box>
      </FlexBetween>
      <Box
        mt="40px"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: theme.palette.primary.light,
          },
          "& .MuiDataGrid-footerContainer": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderTop: "none",
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${theme.palette.secondary[200]} !important`,
          },
        }}
      >
        <MUIDataTable
          title={""}
          data={list_stockopname}
          columns={columnsStockOpname}
          options={options}
        />
        <AddStockopnameModal
          open={openAdd}
          onClose={() => setOpenAdd(false)}
          onSubmit={(value) => HandleSubmitSO(value)}
        />
        <KonfirmasiModal
          titleType={type === "delete" ? "Hapus" : "Import"}
          subtitleType={type === "delete" ? "Menghapus" : "Mengimport"}
          dataWillProses={selectedData?.no_register}
          open={openKonfirmasi}
          onClose={() => setOpenKonfirmasi(false)}
          onSubmit={() => (type === "delete" ? handleDelete() : handleImport())}
        />
        <FloatingAlert
          open={message.length > 0}
          onClose={handleCloseSnackbar}
          message={message}
          severity={status === "failed" ? "error" : status}
        />
      </Box>
    </Box>
  );
};

export default Stockopname;
