import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  MenuItem,
  Grid,
  InputAdornment,
  IconButton,
  useTheme,
} from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useSelector } from "react-redux";

const jabatanOptions = [
  { value: "manajer", text: "Manajer" },
  { value: "admin", text: "Admin" },
  { value: "karyawan_toko", text: "Karyawan Toko" },
  { value: "sales", text: "Sales" },
];
const roleOptions = [
  { value: "operator", text: "Operator" },
  { value: "admin", text: "Admin" },
];

const genderOptions = [
  { value: "laki-laki", text: "Laki-Laki" },
  { value: "perempuan", text: "Perempuan" },
];
const tipeAbsenOptions = [
  { value: "double_absen", text: "Double Absen" },
  { value: "triple_absen", text: "Triple Absen" },
];

const validationSchema = Yup.object({
  nik: Yup.string()
    .matches(/^\d{16}$/, "NIK harus 16 digit angka")
    .required("NIK wajib diisi"),
  nama: Yup.string()
    .matches(/^[a-zA-Z\s]+$/, "Nama hanya boleh huruf")
    .max(100, "Maksimal 100 karakter")
    .required("Nama wajib diisi"),
  email: Yup.string()
    .matches(
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
      "Format email tidak valid"
    )
    .required("Email wajib diisi"),
  jenis_kelamin: Yup.string()
    .oneOf(
      genderOptions.map((opt) => opt.value),
      "Jenis Kelamin tidak valid"
    )
    .required("Jabatan wajib dipilih"),
  jabatan: Yup.string()
    .oneOf(
      jabatanOptions.map((opt) => opt.value),
      "Jabatan tidak valid"
    )
    .required("Jabatan wajib dipilih"),
  lokasi_kerja: Yup.string().required("Lokasi wajib dipilih"),
  no_hp: Yup.string()
    .matches(
      /^08\d{8,10}$/,
      "Nomor HP harus diawali 08 dan terdiri dari 10 hingga 12 digit"
    )
    .required("Nomor HP wajib diisi"),
  password: Yup.string()
    .min(8, "Password minimal 8 karakter")
    .required("Password wajib diisi"),
  role: Yup.string()
    .oneOf(
      roleOptions.map((opt) => opt.value),
      "Role tidak valid"
    )
    .required("Role wajib dipilih"),
  tipe_absen: Yup.string()
    .oneOf(
      tipeAbsenOptions.map((opt) => opt.value),
      "Tipe absen tidak valid"
    )
    .required("Tipe absen wajib dipilih"),
});

const AddKaryawanModal = ({ open, onClose, onSubmit }) => {
  const theme = useTheme();
  const [showPassword, setShowPassword] = useState(false);
  const { list_cabang } = useSelector((state) => state.karyawan);

  const formik = useFormik({
    initialValues: {
      nik: "",
      nama: "",
      email: "",
      jenis_kelamin: "",
      jabatan: "",
      lokasi_kerja: "",
      no_hp: "",
      password: "",
      role: "",
      tipe_absen: "",
    },
    validationSchema,
    onSubmit: (values) => {
      onSubmit(values);
      formik.resetForm();
      onClose();
    },
  });

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Tambah Karyawan</DialogTitle>
      <form onSubmit={formik.handleSubmit}>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="NIK"
                name="nik"
                value={formik.values.nik}
                onChange={formik.handleChange}
                error={formik.touched.nik && Boolean(formik.errors.nik)}
                helperText={formik.touched.nik && formik.errors.nik}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Nama"
                name="nama"
                value={formik.values.nama}
                onChange={formik.handleChange}
                error={formik.touched.nama && Boolean(formik.errors.nama)}
                helperText={formik.touched.nama && formik.errors.nama}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Email"
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                select
                fullWidth
                label="Jenis Kelamin"
                name="jenis_kelamin"
                value={formik.values.jenis_kelamin}
                onChange={formik.handleChange}
                error={
                  formik.touched.jenis_kelamin &&
                  Boolean(formik.errors.jenis_kelamin)
                }
                helperText={
                  formik.touched.jenis_kelamin && formik.errors.jenis_kelamin
                }
              >
                {genderOptions.map(({ text, value }) => (
                  <MenuItem key={value} value={value}>
                    {text}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                select
                fullWidth
                label="Jabatan"
                name="jabatan"
                value={formik.values.jabatan}
                onChange={formik.handleChange}
                error={formik.touched.jabatan && Boolean(formik.errors.jabatan)}
                helperText={formik.touched.jabatan && formik.errors.jabatan}
              >
                {jabatanOptions.map(({ text, value }) => (
                  <MenuItem key={value} value={value}>
                    {text}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                select
                fullWidth
                label="Lokasi Kerja"
                name="lokasi_kerja"
                value={formik.values.lokasi_kerja}
                onChange={formik.handleChange}
                error={
                  formik.touched.lokasi_kerja &&
                  Boolean(formik.errors.lokasi_kerja)
                }
                helperText={
                  formik.touched.lokasi_kerja && formik.errors.lokasi_kerja
                }
              >
                {list_cabang.map(({ id_cab, nama_cab }) => (
                  <MenuItem key={id_cab} value={id_cab}>
                    {`${id_cab} - ${nama_cab}`}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Nomor HP"
                name="no_hp"
                value={formik.values.no_hp}
                onChange={formik.handleChange}
                error={formik.touched.no_hp && Boolean(formik.errors.no_hp)}
                helperText={formik.touched.no_hp && formik.errors.no_hp}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Password"
                type={showPassword ? "text" : "password"}
                name="password"
                value={formik.values.password}
                onChange={formik.handleChange}
                error={
                  formik.touched.password && Boolean(formik.errors.password)
                }
                helperText={formik.touched.password && formik.errors.password}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => setShowPassword((prev) => !prev)}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                select
                fullWidth
                label="Role"
                name="role"
                value={formik.values.role}
                onChange={formik.handleChange}
                error={formik.touched.role && Boolean(formik.errors.role)}
                helperText={formik.touched.role && formik.errors.role}
              >
                {roleOptions.map(({ text, value }) => (
                  <MenuItem key={value} value={value}>
                    {text}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                select
                fullWidth
                label="Tipe Absen"
                name="tipe_absen"
                value={formik.values.tipe_absen}
                onChange={formik.handleChange}
                error={
                  formik.touched.tipe_absen && Boolean(formik.errors.tipe_absen)
                }
                helperText={
                  formik.touched.tipe_absen && formik.errors.tipe_absen
                }
              >
                {tipeAbsenOptions.map(({ text, value }) => (
                  <MenuItem key={value} value={value}>
                    {text}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            type="submit"
            variant="contained"
            color="inherit"
            sx={{
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
            }}
            onClick={onClose}
          >
            Batal
          </Button>
          <Button
            type="submit"
            variant="contained"
            sx={{
              backgroundColor: theme.palette.secondary.light,
              color: theme.palette.background.alt,
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
              "&:hover": {
                backgroundColor: theme.palette.secondary[500], // Tetap sama dengan warna awal
              },
            }}
          >
            Simpan
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default AddKaryawanModal;
