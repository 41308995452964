import API from "api/axiosInstance";
import {
  setIsLoading,
  setListStockopname,
  setMessage,
  setStatus,
} from "./index";

export const fetchListSO = () => async (dispatch) => {
  dispatch(setIsLoading(true));
  try {
    const response = await API.get("/agenda-so");
    dispatch(setIsLoading(false));
    dispatch(setListStockopname(response.data.data));
  } catch (error) {
    dispatch(setIsLoading(false));
    dispatch(setStatus("failed"));
  }
};

export const fetchAddAgendaSO = (reqData) => async (dispatch) => {
  dispatch(setIsLoading(true));
  try {
    const response = await API.post("/agenda-so", reqData);
    dispatch(setMessage(response.data.message));
    dispatch(setStatus("success"));
    dispatch(setIsLoading(false));
  } catch (error) {
    dispatch(setIsLoading(false));
    dispatch(
      setMessage(error.response.data.message ?? "Ada Kendala di Server")
    );
    dispatch(setStatus("failed"));
  }
};

export const fetchDeleteAgendaSO = (no_register) => async (dispatch) => {
  dispatch(setIsLoading(true));
  try {
    const response = await API.post(`/notpresent-agenda`, { no_register });
    dispatch(setMessage(response.data.message));
    dispatch(setStatus("success"));
    dispatch(setIsLoading(false));
  } catch (error) {
    dispatch(setIsLoading(false));
    dispatch(
      setMessage(error.response.data.message ?? "Ada Kendala di Server")
    );
    dispatch(setStatus("failed"));
  }
};

export const fetchImportAgendaSO =
  (no_register, id, lokasi) => async (dispatch) => {
    dispatch(setIsLoading(true));
    try {
      const response = await API.post(`/importbarang`, {
        id,
        no_register,
        lokasi,
      });
      dispatch(setMessage(response.data.message));
      dispatch(setStatus("success"));
      dispatch(setIsLoading(false));
    } catch (error) {
      dispatch(setIsLoading(false));
      dispatch(
        setMessage(error.response.data.message ?? "Ada Kendala di Server")
      );
      dispatch(setStatus("failed"));
    }
  };
