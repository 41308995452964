import React, { useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  MenuItem,
  Grid,
  useTheme,
} from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import moment from "moment";

const validationSchema = Yup.object({
  lokasi_stockopname: Yup.string().required("Lokasi wajib dipilih"),
  tanggalwaktu: Yup.date().required("Tanggal wajib diisi"),
});

const AddStockopnameModal = ({ open, onClose, onSubmit }) => {
  const theme = useTheme();
  const { list_cabang } = useSelector((state) => state.karyawan);

  const formik = useFormik({
    initialValues: {
      no_register: "",
      lokasi_stockopname: "",
      tanggalwaktu: moment(),
    },
    validationSchema,
    onSubmit: (values) => {
      const [idCab, namaCab] = values.lokasi_stockopname.split(" - ");
      const reqData = {
        no_register: values.no_register,
        location: namaCab,
        id_cab: idCab,
        tanggalwaktu: values.tanggalwaktu,
      };
      onSubmit(reqData);
      formik.resetForm();
      onClose();
    },
  });

  useEffect(() => {
    const lokasi = formik.values.lokasi_stockopname;
    const waktu = formik.values.tanggalwaktu;
    if (lokasi && waktu) {
      const tanggal = moment(waktu).format("YYYYMMDD");
      const newNoRegister = `SO-${lokasi.split(" - ")[0]}-${tanggal}`;
      formik.setFieldValue("no_register", newNoRegister);
    }
  }, [formik.values.lokasi_stockopname, formik.values.tanggalwaktu]);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Tambah Agenda Stockopname</DialogTitle>
      <form onSubmit={formik.handleSubmit}>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="No Register"
                name="no_register"
                value={formik.values.no_register}
                disabled
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                select
                fullWidth
                label="Lokasi Stockopname"
                name="lokasi_stockopname"
                value={formik.values.lokasi_stockopname}
                onChange={formik.handleChange}
                error={
                  formik.touched.lokasi_stockopname &&
                  Boolean(formik.errors.lokasi_stockopname)
                }
                helperText={
                  formik.touched.lokasi_stockopname &&
                  formik.errors.lokasi_stockopname
                }
              >
                {list_cabang.map(({ id_cab, nama_cab }) => (
                  <MenuItem key={id_cab} value={id_cab + " - " + nama_cab}>
                    {`${id_cab} - ${nama_cab}`}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DateTimePicker
                  label="Tanggal & Waktu"
                  value={formik.values.tanggalwaktu}
                  onChange={(val) => formik.setFieldValue("tanggalwaktu", val)}
                  renderInput={(params) => <TextField {...params} fullWidth />}
                />
              </LocalizationProvider>
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button
            variant="contained"
            color="inherit"
            onClick={onClose}
            sx={{ fontSize: "14px", fontWeight: "bold", padding: "10px 20px" }}
          >
            Batal
          </Button>
          <Button
            type="submit"
            variant="contained"
            sx={{
              backgroundColor: theme.palette.secondary.light,
              color: theme.palette.background.alt,
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
              "&:hover": {
                backgroundColor: theme.palette.secondary[500],
              },
            }}
          >
            Simpan
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default AddStockopnameModal;
