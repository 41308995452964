import React from "react";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

const PrivateRoute = ({ element, redirectTo = "/login" }) => {
  const user = useSelector((state) => state.user.accessToken);

  if (!user) {
    return <Navigate to={redirectTo} replace />;
  }

  return element;
};

export default PrivateRoute;
