import { CssBaseline, ThemeProvider } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import { useMemo } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { themeSettings } from "theme";
import Layout from "scenes/layout";
import Dashboard from "scenes/dashboard";
import Karyawan from "scenes/karyawan";
import Login from "scenes/login";
import PrivateRoute from "routes/PrivateRoute";
import NotFound from "scenes/notfound";
import LoadingBackdrop from "components/LoadingBackdrop";
import Notifikasi from "scenes/notifikasi";
import Stockopname from "scenes/stockopname";
import Barang from "scenes/barang";
import AbsensiHariIni from "scenes/absensihariini";
import RekapAbsensiBulanan from "scenes/rekapabsensibulanan";

function App() {
  const theme = useMemo(() => createTheme(themeSettings("light")), []);
  return (
    <div className="app">
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <LoadingBackdrop />
          <Routes>
            {/* Rute untuk login */}
            <Route path="/login" element={<Login />} />

            {/* Rute utama dengan Layout */}
            <Route element={<Layout />}>
              {/* Jika belum login, arahkan ke login */}
              <Route
                path="/"
                element={
                  <PrivateRoute
                    element={<Navigate to="/dashboard" replace />}
                    redirectTo="/login"
                  />
                }
              />
              <Route
                path="/dashboard"
                element={<PrivateRoute element={<Dashboard />} />}
              />
              <Route
                path="/barang"
                element={<PrivateRoute element={<Barang />} />}
              />
              <Route
                path="/karyawan"
                element={<PrivateRoute element={<Karyawan />} />}
              />
               <Route
                path="/notifikasi"
                element={<PrivateRoute element={<Notifikasi />} />}
              />
               <Route
                path="/stockopname"
                element={<PrivateRoute element={<Stockopname />} />}
              />
               <Route
                path="/absensihariini"
                element={<PrivateRoute element={<AbsensiHariIni />} />}
              />
               <Route
                path="/rekapabsensibulanan"
                element={<PrivateRoute element={<RekapAbsensiBulanan />} />}
              />
            </Route>
            <Route path="*" element={<NotFound />} />
          </Routes>
        </ThemeProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
