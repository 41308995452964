import React, { useEffect } from "react";
import { Box, Button, IconButton, useTheme } from "@mui/material";
import Header from "components/Header";
import { useDispatch, useSelector } from "react-redux";
import {
  CloudSyncOutlined,
  Delete,
  Edit,
  Groups2Outlined,
} from "@mui/icons-material";
import MUIDataTable from "mui-datatables";
import FlexBetween from "components/FlexBetween";
import { fetchListSO } from "state/stockopnameactions";
import moment from "moment";
import "moment/locale/id";
import { fetchListAbsensiBulanan } from "state/absensiActions";
moment.locale("id");

const RekapAbsensiBulanan = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { list_absensi_bulanan } = useSelector((state) => state.absensi);
  useEffect(() => {
    dispatch(fetchListAbsensiBulanan());
  }, []);


  const handleEdit = (id) => {
  };

  const handleDelete = (id) => {
  };
  const columnsKaryawan = [
    {
      name: "id_karyawan",
      label: "ID Karyawan",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "nama_karyawan",
      label: "Nama Karyawan",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "lokasi_kerja",
      label: "Toko Cabang",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "bulan",
      label: "Bulan",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "jumlah_hadir",
      label: "Jumlah Hadir",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "jumlah_izin",
      label: "Jumlah Izin",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "jumlah_tidak_hadir",
      label: "Jumlah Tidak Hadir",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "total_telat",
      label: "Total Telat",
      options: {
        filter: true,
        sort: false,
        options: {
          filter: true,
          sort: false,
          customBodyRender: (value) => `${Number(value).toFixed(2)} Menit`,
        },
      },
    },
  ];

  const options = {
    selectableRows: "none",
    filterType: "dropdown",
    fixedHeader: false,
    fixedSelectColumn: false,
  };

  return (
    <Box m="1.5rem 2.5rem">
      <Header title="Rekap Absensi Bulanan" subtitle="Daftar absensi bulanan karyawan." />
      <Box
        mt="40px"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: theme.palette.primary.light,
          },
          "& .MuiDataGrid-footerContainer": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderTop: "none",
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${theme.palette.secondary[200]} !important`,
          },
        }}
      >
        <MUIDataTable
          title={""}
          data={list_absensi_bulanan}
          columns={columnsKaryawan}
          options={options}
        />
      </Box>
    </Box>
  );
};

export default RekapAbsensiBulanan;
