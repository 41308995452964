import API from "api/axiosInstance";
import {
  setIsLoading,
  setListCabang,
  setListKaryawan,
  setMessage,
  setStatus,
} from "./index";

export const fetchListKaryawan = () => async (dispatch) => {
  dispatch(setIsLoading(true));
  try {
    const response = await API.get("/web-karyawan");
    dispatch(setIsLoading(false));
    dispatch(setListKaryawan(response.data.list_karyawan));
  } catch (error) {
    dispatch(setIsLoading(false));
    dispatch(setStatus("failed"));
  }
};

export const fetchListCabang = () => async (dispatch) => {
  dispatch(setIsLoading(true));
  try {
    const response = await API.get("/cabang");
    dispatch(setIsLoading(false));
    dispatch(setListCabang(response.data.cabangData));
  } catch (error) {
    dispatch(setIsLoading(false));
    dispatch(setStatus("failed"));
  }
};

export const fetchDeleteKaryawan = (id) => async (dispatch) => {
  dispatch(setIsLoading(true));
  try {
    const response = await API.post(`/karyawan/${id}`);
    dispatch(setMessage(response.data.message));
    dispatch(setStatus("success"));
    dispatch(setIsLoading(false));
  } catch (error) {
    dispatch(setIsLoading(false));
    dispatch(setStatus("failed"));
  }
};

export const fetchAddKaryawan = (redData) => async (dispatch) => {
  dispatch(setIsLoading(true));
  try {
    const response = await API.post("/karyawan", redData);
    dispatch(setMessage(response.data.message));
    dispatch(setStatus("success"));
    dispatch(setIsLoading(false));
  } catch (error) {
    dispatch(setIsLoading(false));
    dispatch(
      setMessage(error.response.data.message ?? "Ada Kendala di Server")
    );
    dispatch(setStatus("failed"));
  }
};
