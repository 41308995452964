import React, { useEffect } from "react";
import { Box, Button, IconButton, Typography, useTheme } from "@mui/material";
import Header from "components/Header";
import { useDispatch, useSelector } from "react-redux";
import { Delete, Edit, Groups2Outlined } from "@mui/icons-material";
import MUIDataTable from "mui-datatables";
import FlexBetween from "components/FlexBetween";
import { fetchListNotifikasi } from "state/notifikasiActions";
import moment from "moment";
import "moment/locale/id";
moment.locale("id");

const Notifikasi = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { list_notifikasi } = useSelector((state) => state.notifikasi);
  useEffect(() => {
    dispatch(fetchListNotifikasi());
  }, []);


  const handleEdit = (id) => {
  };

  const handleDelete = (id) => {
  };
  const columnsKaryawan = [
    {
      name: "judul_notifikasi",
      label: "Judul",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "isi_notifikasi",
      label: "Isi",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "waktu_notifikasi",
      label: "Waktu",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return moment(value).format("DD MMMM YYYY - HH:mm");
        },
      },
    },
    {
      name: "url_notifikasi",
      label: "URL",
      options: {
        filter: true,
        sort: false,
      },
    },
    // {
    //   name: "aksi",
    //   label: "Aksi",
    //   options: {
    //     filter: false,
    //     sort: false,
    //     customBodyRender: (value, tableMeta) => {
    //       const id = tableMeta.rowData[0];
    //       return (
    //         <>
    //           <IconButton color="info" onClick={() => handleEdit(id)}>
    //             <Edit />
    //           </IconButton>
    //           <IconButton color="error" onClick={() => handleDelete(id)}>
    //             <Delete />
    //           </IconButton>
    //         </>
    //       );
    //     },
    //   },
    // },
  ];

  const options = {
    selectableRows: "none", 
    filterType: 'dropdown',
    fixedHeader: false,
    fixedSelectColumn: false,
  };

  return (
    <Box m="1.5rem 2.5rem">
      <FlexBetween>
        <Header title="Notifikasi" subtitle="Daftar notifikasi yang akan dikirim ke perangkat karyawan." />

        <Box>
          <Button
            sx={{
              backgroundColor: theme.palette.secondary.light,
              color: theme.palette.background.alt,
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
              "&:hover": {
                backgroundColor: theme.palette.secondary.light, // Tetap sama dengan warna awal
              },
            }}
            onClick={() => {}}
          >
            <Groups2Outlined sx={{ mr: "10px" }} />
            Tambah Notifikasi
          </Button>
        </Box>
      </FlexBetween>
      <Box
        mt="40px"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: theme.palette.primary.light,
          },
          "& .MuiDataGrid-footerContainer": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderTop: "none",
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${theme.palette.secondary[200]} !important`,
          },
        }}
      >
        <MUIDataTable
          title={""}
          data={list_notifikasi}
          columns={columnsKaryawan}
          options={options}
        />
      </Box>
    </Box>
  );
};

export default Notifikasi;
