import API from "api/axiosInstance";
import { setIsLoading, setListNotifikasi, setStatus } from "./index";

export const fetchListNotifikasi = () => async (dispatch) => {
  dispatch(setIsLoading(true));
  try {
    const response = await API.get("/notifikasi");
    dispatch(setIsLoading(false));
    dispatch(setListNotifikasi(response.data.data));
  } catch (error) {
    dispatch(setIsLoading(false));
    dispatch(setStatus("failed"));
  }
};
