import API from "api/axiosInstance";
import { setAccessToken, setDataUser, setIsLoading, setStatus } from "./index";

export const fetchLogin = (email, password) => async (dispatch) => {
  dispatch(setIsLoading(true));

  try {
    const response = await API.post("/auth/web-login", {
      email,
      pass: password,
    });
    dispatch(setIsLoading(false));
    dispatch(setStatus("success"));
    dispatch(setDataUser(response.data.user_data));
    dispatch(setAccessToken(response.data.accessToken));
  } catch (error) {
    dispatch(setIsLoading(false));
    dispatch(setStatus("failed"));
  }
};

export const fetchLogout = () => async (dispatch) => {
  dispatch(setIsLoading(true));
  setTimeout(() => {
    dispatch(setDataUser({}));
    dispatch(setAccessToken(null));
    dispatch(setIsLoading(false));
  }, 3000);
};
