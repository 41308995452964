import API from "api/axiosInstance";
import {
  setIsLoading,
  setListAbsensiBulanan,
  setListAbsensiHariIni,
  setStatus,
} from "./index";

export const fetchListAbsensiHariini = () => async (dispatch) => {
  dispatch(setIsLoading(true));
  try {
    const response = await API.get("/api/attendance/absenhariini");
    dispatch(setIsLoading(false));
    dispatch(setListAbsensiHariIni(response.data.list_absensi));
  } catch (error) {
    dispatch(setIsLoading(false));
    dispatch(setStatus("failed"));
  }
};

export const fetchListAbsensiBulanan = () => async (dispatch) => {
  dispatch(setIsLoading(true));
  try {
    const response = await API.get("/api/attendance/absenbulanan");
    dispatch(setIsLoading(false));
    dispatch(setListAbsensiBulanan(response.data.list_absensi_bulanan));
  } catch (error) {
    dispatch(setIsLoading(false));
    dispatch(setStatus("failed"));
  }
};
