import React, { useEffect, useState } from "react";
import {
  Container,
  Box,
  TextField,
  Button,
  Typography,
  Paper,
  IconButton,
  InputAdornment,
  useTheme,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import bgImage from "assets/bg.jpeg";
import logoImage from "assets/logo-prima.png";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchLogin } from "state/userActions";
import FloatingAlert from "components/FloatingAlert";
import { setMessage, setStatus } from "state";

const Login = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { accessToken: access_token } = useSelector((state) => state.user);
  const { status, message } = useSelector((state) => state.global);

  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  useEffect(() => {
    if (status === "success" && access_token) {
      navigate("/dashboard");
    }
  }, [status, access_token]);

  const handleCloseSnackbar = () => {
    dispatch(setStatus("default"));
    dispatch(setMessage(""));
  };
  return (
    <Container
      maxWidth="full"
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        padding: 2,
        backgroundImage: `url(${bgImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        position: "relative",
      }}
    >
      <Paper
        elevation={3}
        sx={{
          padding: 4,
          borderRadius: 2,
          width: "100%",
          maxWidth: "400px",
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <img
            src={logoImage}
            alt="Logo"
            style={{ width: "200px", height: "auto" }}
          />
        </Box>
        <Typography
          variant="h3"
          align="center"
          sx={{ marginTop: 4, marginBottom: 4 }}
        >
          Selamat Datang
        </Typography>
        <Box
          component="form"
          sx={{ display: "flex", flexDirection: "column", gap: 2 }}
        >
          <TextField
            className="texfieldLogin"
            label="Email"
            variant="outlined"
            fullWidth
            required
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            sx={{
              "& .MuiInputLabel-root": {
                color: "gray",
              },
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "gray",
                },
                "&:hover fieldset": {
                  borderColor: theme.palette.secondary[500],
                },
              },
              "& .MuiInputBase-input": {
                color: "black",
              },
            }}
          />
          <TextField
            label="Password"
            type={showPassword ? "text" : "password"}
            variant="outlined"
            fullWidth
            required
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                dispatch(fetchLogin(email, password));
              }
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setShowPassword((prev) => !prev)}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            sx={{
              "& .MuiInputLabel-root": {
                color: "gray",
              },
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "gray",
                },
                "&:hover fieldset": {
                  borderColor: theme.palette.secondary[500],
                },
              },
              "& .MuiInputBase-input": {
                color: "black",
              },
            }}
          />
          <Button
            variant="contained"
            color="primary"
            fullWidth
            sx={{
              marginTop: 2,
              backgroundColor: theme.palette.secondary[400],
              color: "#fff",
              padding: "12px",
              fontWeight: "bold",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
              textTransform: "none",
              "&:hover": {
                backgroundColor: theme.palette.secondary[300],
                boxShadow: "0 6px 12px rgba(0, 0, 0, 0.2)",
              },
            }}
            onClick={() => {
              dispatch(fetchLogin(email, password));
            }}
          >
            Login
          </Button>
        </Box>
      </Paper>
      <FloatingAlert
        open={status === "failed" && message.length > 0}
        onClose={handleCloseSnackbar}
        message={message}
        severity={"error"}
      />
    </Container>
  );
};

export default Login;
