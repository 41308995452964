import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  useTheme,
} from "@mui/material";

const KonfirmasiModal = ({
  titleType,
  subtitleType,
  dataWillProses,
  open,
  onClose,
  onSubmit,
}) => {
  const theme = useTheme();

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Konfirmasi {titleType}</DialogTitle>
      <DialogContent>
        <Typography>
          Apakah Anda yakin ingin {subtitleType} data {dataWillProses}? Tindakan
          ini tidak bisa dibatalkan.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          type="submit"
          variant="contained"
          color="inherit"
          sx={{
            fontSize: "14px",
            fontWeight: "bold",
            padding: "10px 20px",
          }}
          onClick={onClose}
        >
          Batal
        </Button>
        <Button
          variant="contained"
          sx={{
            backgroundColor: theme.palette.secondary.light,
            color: theme.palette.background.alt,
            fontSize: "14px",
            fontWeight: "bold",
            padding: "10px 20px",
            "&:hover": {
              backgroundColor: theme.palette.secondary[500], // Tetap sama dengan warna awal
            },
          }}
          onClick={onSubmit}
        >
          Hapus
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default KonfirmasiModal;
