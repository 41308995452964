import React, { useEffect } from "react";
import { Box, useTheme } from "@mui/material";
import Header from "components/Header";
import { useDispatch, useSelector } from "react-redux";
import MUIDataTable from "mui-datatables";
import { fetchListAbsensiHariini } from "state/absensiActions";
import moment from "moment";
import "moment/locale/id";
moment.locale("id");

const AbsensiHariIni = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { list_absensi_hariini } = useSelector((state) => state.absensi);
  useEffect(() => {
    dispatch(fetchListAbsensiHariini());
  }, []);


  const handleEdit = (id) => {
  };

  const handleDelete = (id) => {
  };
  const columnsKaryawan = [
    {
      name: "id_karyawan",
      label: "ID Karyawan",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "nama_karyawan",
      label: "Nama Karyawan",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "check_in",
      label: "Waktu Check In",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          const today = moment().format("YYYY-MM-DD");
          const dateTime = moment(`${today} ${value}`);
          return dateTime.format("DD/MM/YYYY - HH:mm:ss");
        },
      },
    },
    {
      name: "location_check_in",
      label: "Lokasi Check In",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "menit_telat",
      label: "Menit Telat",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => value + " Menit",
      },
    },
  ];

  const options = {
    selectableRows: "none",
    filterType: "dropdown",
    fixedHeader: false,
    fixedSelectColumn: false,
  };

  return (
    <Box m="1.5rem 2.5rem">
      <Header
        title="Absensi Hari Ini"
        subtitle="Daftar absensi karyawan hari ini."
      />

      <Box
        mt="40px"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: theme.palette.primary.light,
          },
          "& .MuiDataGrid-footerContainer": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderTop: "none",
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${theme.palette.secondary[200]} !important`,
          },
        }}
      >
        <MUIDataTable
          title={""}
          data={list_absensi_hariini}
          columns={columnsKaryawan}
          options={options}
        />
      </Box>
    </Box>
  );
};

export default AbsensiHariIni;
