import React from "react";
import { Snackbar, Alert, Typography } from "@mui/material";

const FloatingAlert = ({ open, onClose, message, severity = "success" }) => {
  return (
    <Snackbar
      open={open}
      autoHideDuration={5000} // Alert otomatis hilang dalam 3 detik
      onClose={onClose}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
    >
      <Alert
        onClose={onClose}
        severity={severity}
        variant="filled"
      >
        <Typography variant="h5" textAlign={"center"}>
          {message}
        </Typography>
      </Alert>
    </Snackbar>
  );
};

export default FloatingAlert;
