import React from "react";
import { Typography, Button, useTheme } from "@mui/material";
import { Link } from "react-router-dom";

const NotFound = () => {
  const theme = useTheme();
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <Typography variant="h3" color="error">
        404 - Halaman tidak ditemukan
      </Typography>
      <Button
        variant="contained"
        color="primary"
        sx={{
          marginTop: 2,
          backgroundColor: theme.palette.secondary[400],
          color: "#fff",
          padding: "12px",
          fontWeight: "bold",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          textTransform: "none",
          "&:hover": {
            backgroundColor: theme.palette.secondary[300],
            boxShadow: "0 6px 12px rgba(0, 0, 0, 0.2)",
          },
        }}
      >
        <Link to="/" style={{ color: "white", textDecoration: "none" }}>
          Kembali ke Halaman Utama
        </Link>
      </Button>
    </div>
  );
};

export default NotFound;
