import axios from "axios";
import { setMessage, setStatus, store } from "state";

const API = axios.create({
  baseURL: "https://api.primacentre.id", // Ganti dengan URL backend kamu
  headers: {
    "Content-Type": "application/json",
  },
});

// Interceptor untuk menambahkan token auth
API.interceptors.request.use(
  (config) => {
    const state = store.getState();
    const token = state.user.accessToken; // Ambil token dari Redux Persist
    if (token) {
      config.headers.Authorization = token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Interceptor Response (Tangani Error)
API.interceptors.response.use(
  (response) => response, // Jika sukses, lanjutkan
  (error) => {
    const errorMessage =
      error.response?.data?.error || "Terjadi kesalahan pada server";

    // Dispatch ke Redux Global State
    store.dispatch(setMessage(errorMessage));
    store.dispatch(setStatus("failed"));

    return Promise.reject(error);
  }
);
export default API;
