import { createSlice, configureStore } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage"; // Menggunakan local storage
import { combineReducers } from "redux";

// Global State (Tidak dipersist)
const globalInitialState = {
  isLoading: false,
  status: "default",
  message: "",
};

const globalSlice = createSlice({
  name: "global",
  initialState: globalInitialState,
  reducers: {
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setStatus: (state, action) => {
      state.status = action.payload;
    },
    setMessage: (state, action) => {
      state.message = action.payload;
    },
  },
});

// User State (Dipersist)
const userInitialState = {
  user_data: {},
  accessToken: null,
};

const userSlice = createSlice({
  name: "user",
  initialState: userInitialState,
  reducers: {
    setDataUser: (state, action) => {
      state.user_data = action.payload;
    },
    setAccessToken: (state, action) => {
      state.accessToken = action.payload;
    },
  },
});

// Karyawan State (Tidak dipersist)
const karyawanInitialState = {
  list_karyawan: [],
  list_cabang: [],
};

const karyawanSlice = createSlice({
  name: "karyawan",
  initialState: karyawanInitialState,
  reducers: {
    setListKaryawan: (state, action) => {
      state.list_karyawan = action.payload;
    },
    setListCabang: (state, action) => {
      state.list_cabang = action.payload;
    },
  },
});

// Notifikasi State (Tidak dipersist)
const notifikasiInitialState = {
  list_notifikasi: [],
};

const notifikasiSlice = createSlice({
  name: "notifikasi",
  initialState: notifikasiInitialState,
  reducers: {
    setListNotifikasi: (state, action) => {
      state.list_notifikasi = action.payload;
    },
  },
});

// SO State (Tidak dipersist)
const stockopnameInitialState = {
  list_stockopname: [],
};

const stockopnameSlice = createSlice({
  name: "stockopname",
  initialState: stockopnameInitialState,
  reducers: {
    setListStockopname: (state, action) => {
      state.list_stockopname = action.payload;
    },
  },
});

// SO State (Tidak dipersist)
const absensiInitialState = {
  list_absensi_hariini: [],
  list_absensi_bulanan: [],
};

const absensiSlice = createSlice({
  name: "absensi",
  initialState: absensiInitialState,
  reducers: {
    setListAbsensiHariIni: (state, action) => {
      state.list_absensi_hariini = action.payload;
    },
    setListAbsensiBulanan: (state, action) => {
      state.list_absensi_bulanan = action.payload;
    },
  },
});

const persistConfig = {
  key: "user",
  storage,
};

const rootReducer = combineReducers({
  global: globalSlice.reducer,
  user: persistReducer(persistConfig, userSlice.reducer),
  karyawan: karyawanSlice.reducer,
  notifikasi: notifikasiSlice.reducer,
  so: stockopnameSlice.reducer,
  absensi: absensiSlice.reducer,
});

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

const persistor = persistStore(store);

export { store, persistor };
export const { setIsLoading, setStatus, setMessage } = globalSlice.actions;
export const { setDataUser, setAccessToken } = userSlice.actions;
export const { setListKaryawan, setListCabang } = karyawanSlice.actions;
export const { setListNotifikasi } = notifikasiSlice.actions;
export const { setListStockopname } = stockopnameSlice.actions;
export const { setListAbsensiHariIni, setListAbsensiBulanan } =
  absensiSlice.actions;
